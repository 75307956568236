// utils
import getSvgIcon from '@rflex-spa/react-core/dist/base/utils/getSvgIcon';
// routes
import {
  ROUTES,
  APP_REPORT_ROUTES,
  APP_WORKERS_ROUTES,
  APP_PROTOCOL_IMPROVEMENT_ROUTES,
  APP_MAINTAINERS_ROUTES,
  APP_LEAVE_ROUTES,
  APP_IMPORTERS_ROUTES,
  APP_DOCUMENT_MANAGEMENT_ROUTES
} from './paths';

// ----------------------------------------------------------------------

const ICONS = {
  analytics: getSvgIcon('ic_analytics'),
  cardAccount: getSvgIcon('ic_card_account'),
  staack: getSvgIcon('ic_report'),
  check: getSvgIcon('ic_cierreperiodo'),
  table: getSvgIcon('ic_unidades'),
  repocerti: getSvgIcon('ic_repocerti'),
  workers: getSvgIcon('ic_workers'),
  clockDevices: getSvgIcon('ic_clock_devices'),
  spreadsheets: getSvgIcon('ic_spreadsheets'),
  mantainers: getSvgIcon('ic_mantainer'),
  protocolImprovement: getSvgIcon('ic_procotol_improvement'),
  leave: getSvgIcon('ic_permisos'),
  importers: getSvgIcon('ic_importers'),
  documents: getSvgIcon('ic_document_management'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general',
    items: [
      {
        code: 'home',
        title: 'Home',
        path: ROUTES.home,
        icon: ICONS.analytics,
      },
      {
        code: 'reports',
        title: 'Reportes',
        path: APP_REPORT_ROUTES.root,
        icon: ICONS.staack,
      },
      {
        code: 'payment-periods',
        title: 'Periodos de pago',
        path: ROUTES.closingPeriods,
        icon: ICONS.check,
      },
      {
        code: 'workers-dropdown',
        title: 'Trabajadores',
        path: APP_WORKERS_ROUTES.root,
        icon: ICONS.workers,
        children: [
          {
            code: 'mantenedor-trabajadores',
            title: 'Mantenedor trabajadores',
            path: APP_WORKERS_ROUTES.workers,
          },
          {
            code: 'ficha-unica',
            title: 'Ficha única',
            path: APP_WORKERS_ROUTES.singleSheet,
          },
        ],
      },
      {
        code: 'units',
        title: 'Unidades',
        path: ROUTES.unitsMaintainer,
        icon: ICONS.table,
      },
      {
        code: 'maintainers',
        title: 'Mantenedores',
        path: APP_MAINTAINERS_ROUTES.root,
        icon: ICONS.mantainers,
      },
      {
        code: 'hash-validation',
        title: 'Validación de Hash',
        path: ROUTES.hashValidation,
        icon: ICONS.check,
      },
      {
        code: 'clock-devices',
        title: 'Relojes',
        path: ROUTES.clockDevices,
        icon: ICONS.clockDevices,
      },
      {
        code: 'planillas',
        title: 'Planillas',
        path: ROUTES.spreadsheets,
        icon: ICONS.spreadsheets,
      },
      {
        code: 'protocol-improvement',
        title: 'Protocolo de bonificaciones',
        path: APP_PROTOCOL_IMPROVEMENT_ROUTES.root,
        icon: ICONS.protocolImprovement,
      },
      {
        code: 'permisos',
        title: 'Permisos',
        path: APP_LEAVE_ROUTES.root,
        icon: ICONS.leave,
      },
      {
        code: 'importers',
        title: 'Importadores',
        path: APP_IMPORTERS_ROUTES.root,
        icon: ICONS.importers,
      },
      {
        code: 'document-management',
        title: 'Gestión documental',
        path: APP_DOCUMENT_MANAGEMENT_ROUTES.root,
        icon: ICONS.documents,
      },
      {
        code: 'monitor-relojes',
        title: 'Monitor de relojes',
        path: ROUTES.clockMonitor,
        icon: ICONS.clockDevices,
      }
    ],
  },
];

export default navConfig;
